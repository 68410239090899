import MuiTab from '@material-ui/core/Tab'
import MuiTabs from '@material-ui/core/Tabs'
import clsx from 'clsx'
import { FC, ReactNode, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { useHashParams } from '../../core/hooks/useHashParams'
import { useStyles } from './styles'

type Tab = {
  disabled?: boolean
  label: string
  hasError?: boolean
  index: number
}

type Props = Partial<{
  dense: boolean
  tabs: Omit<Tab, 'index'>[]
  children: (index: number) => ReactNode
  withHash: boolean
  testAttrNamespace?: string
  onLoad(newindex: number): void
}>

const Tabs: FC<Props> = props => {
  const { hashParams, addParam } = useHashParams()
  const tabs = useMemo(() => props.tabs || [], [props.tabs])
  const currentTab = useMemo(
    () => tabs.find(tab => tab.label === (hashParams['tab'] as string)),
    [hashParams, tabs]
  )
  const [index, setIndex] = useState(
    (currentTab && tabs.indexOf(currentTab)) || 0
  )

  useUpdateEffect(() => {
    props.withHash && addParam({ tab: tabs[index].label })
  }, [index])

  const classes = useStyles()

  return (
    <>
      <MuiTabs
        className={clsx([classes.root, { [classes.mb5]: !props.dense }])}
        TabScrollButtonProps={{
          className: classes.tabArrow,
          classes: { disabled: classes.disabledTabArrow },
        }}
        value={index}
        variant="scrollable"
        onChange={(_, newindex: number) => {
          setIndex(newindex)
          props.onLoad && props.onLoad(newindex)
        }}
        TabIndicatorProps={{
          className: clsx([
            classes.tabIndicator,
            tabs[index]?.hasError && classes.errorBackground,
          ]),
        }}
        classes={{ scroller: classes.scroller }}
      >
        {props.tabs?.map((tab, index) => (
          <MuiTab
            key={index}
            className={clsx([
              { [classes.denseTab]: props.dense },
              classes.tab,
              tab.hasError && classes.errorColor,
            ])}
            disabled={tab.disabled}
            label={tab.label}
            tabIndex={0}
          />
        ))}
      </MuiTabs>
      {props.children && props.children(index)}
    </>
  )
}

export default Tabs
