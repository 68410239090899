import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {},
  hideButton: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  divider: {
    marginLeft: theme.spacing(4),
    height: `calc(100% - ${theme.spacing(7)}px)`,
  },
}))
