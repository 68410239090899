import Box from '@material-ui/core/Box'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { FC, useMemo } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Prompt, useParams } from 'react-router-dom'
import { useUpdateEffect } from 'react-use'
import * as yup from 'yup'
import DetailedContent from '../../../components/DetailedContent'
import FieldGroupTitle from '../../../components/FieldGroupTitle'
import OrderableCard from '../../../components/OrderableCard'
import SelectField from '../../../components/SelectField'
import TextField from '../../../components/TextField'
import Title from '../../../components/Title'
import { ApiDataType } from '../../../core/api'
import { useApiCreate } from '../../../core/hooks/useApiCreate'
import { useApiData } from '../../../core/hooks/useApiData'
import { useApiMutation } from '../../../core/hooks/useApiMutation'
import { useApiUpdate } from '../../../core/hooks/useApiUpdate'
import { useHasFieldErrors } from '../../../core/hooks/useHasFieldsError'
import { idNameObjToOption } from '../../../core/tools/idNameObjToOption'
import ComponentActions from '../../ComponentActions'
import { useStyles } from './styles'

type Props = Partial<{
  create: boolean
  edit: boolean
}>

const SocialFeedDetailView: FC<Props> = ({ create, edit }) => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const apiUpdate = useApiUpdate({ apiKey: 'components', id: parseInt(id) })
  const apiCreate = useApiCreate({
    apiKey: 'components',
  })
  const { apiMutate, duplicateOnMutate } = useApiMutation({
    apiCreate,
    apiUpdate,
    pathnameOnCreate: '/components/jlc-social-feed',
  })
  const component = useApiData<ApiDataType.Component.JLC.SocialFeed>({
    apiKey: 'components',
    id,
    avoid: create,
  })
  const textComponents = useApiData<ApiDataType.Component.Text[]>({
    apiKey: 'components',
    params: { slug: 'text' },
  })
  const linkComponents = useApiData<ApiDataType.Component.Link[]>({
    apiKey: 'components',
    params: { slug: 'link', withContent: 'true' },
  })

  const validationSchema = yup.object({
    name: yup.string().required(t('formik.errors.required')),
    content: yup.object({
      title: yup.number().nullable().required(t('formik.errors.required')),
      subtitle: yup.number().nullable(),
      instagram: yup.string().required(t('formik.errors.required')),
      links: yup
        .array(
          yup.object({
            type: yup.string().default(''),
            link: yup.number().nullable(),
          })
        )
        .default([]),
    }),
    options: yup.object({}),
  })

  type FormFields = yup.TypeOf<typeof validationSchema>

  const initialValues = component || {
    name: '',
    content: {
      title: null,
      subtitle: null,
      instagram: '',
      links: [
        {
          type: 'facebook',
          link: null,
        },
        {
          type: 'instagram',
          link: null,
        },
        {
          type: 'linkedin',
          link: null,
        },
        {
          type: 'twitter',
          link: null,
        },
        {
          type: 'youtube',
          link: null,
        },
        {
          type: 'pinterest',
          link: null,
        },
      ],
    },
    options: {},
  }

  const formik = useFormik<FormFields>({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      const body = {
        id: component?.id,
        template: component?.template,
        slug: 'jlc-social-feed',
        ...values,
      }

      await apiMutate({ body, create, edit })

      actions.resetForm({ values })
    },
  })

  useUpdateEffect(() => {
    formik.resetForm({ values: initialValues })
  }, [component])

  const hasFieldErrors = useHasFieldErrors(formik)

  const hasOverviewError = hasFieldErrors(['name'])
  const hasContentError = hasFieldErrors([
    'content.title',
    'content.subtitle',
    'content.instagram',
  ])

  const isDisabled = useMemo(
    () => (edit && !component) || formik.isSubmitting,
    [edit, component, formik.isSubmitting]
  )

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Prompt
        when={!isDisabled && formik.dirty}
        message={_ => t('dialogs.alerts.unsaved_modifications') as string}
      />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Title
            title={
              create
                ? formik.values.name || t('views.social_feed.create.title')
                : formik.values.name || component?.name || '...'
            }
            subtitle={
              create
                ? t('views.social_feed.create.subtitle')
                : t('views.social_feed.edit.subtitle')
            }
            actionRender={
              <ComponentActions
                showMore={edit}
                isDirty={formik.dirty}
                isDisabled={isDisabled}
                isSubmitting={formik.isSubmitting}
                onDuplicate={() => {
                  duplicateOnMutate()
                  formik.submitForm()
                }}
              />
            }
            withBackButton
          />
          <DetailedContent
            hints={[
              'Fill all tabs to save a new Social Feed component in your library.',
              'In the Content Tab, you must add the Title, which will be displayed at the top of the component, above the images; you can add the Subtitle, which will be displayed above the social media icons.',
              'Remember to insert the Token in order to get the Instagram feed.',
            ]}
            tabs={[
              {
                label: t('common.overview'),
                subtitle: t('common.general_information'),
                hasError: hasOverviewError,
                render: (
                  <Box>
                    <TextField
                      disabled={isDisabled}
                      error={formik.errors.name}
                      label={t('common.name')}
                      name="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      touched={formik.touched.name}
                      value={formik.values.name}
                      required
                    />
                  </Box>
                ),
              },
              {
                label: t('common.content'),
                subtitle: t('common.component_information'),
                hasError: hasContentError,
                render: (
                  <Box>
                    <SelectField
                      disabled={isDisabled}
                      label={t('common.title')}
                      name="content.title"
                      error={formik.errors.content?.title}
                      onBlur={formik.handleBlur}
                      options={textComponents?.map(c => idNameObjToOption(c))}
                      setValueFunc={formik.setFieldValue}
                      touched={formik.touched.content?.title}
                      value={formik.values.content.title}
                      redirection={{
                        pathname: '/components/text',
                      }}
                      required
                    />
                    <SelectField
                      disabled={isDisabled}
                      label={t('common.subtitle')}
                      name="content.subtitle"
                      error={formik.errors.content?.subtitle}
                      onBlur={formik.handleBlur}
                      options={textComponents?.map(c => idNameObjToOption(c))}
                      setValueFunc={formik.setFieldValue}
                      touched={formik.touched.content?.subtitle}
                      value={formik.values.content.subtitle}
                      redirection={{
                        pathname: '/components/text',
                      }}
                    />
                    <FieldGroupTitle>{t('common.instagram')}</FieldGroupTitle>
                    <TextField
                      name="content.instagram"
                      label={t('common.token')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.content?.instagram}
                      touched={formik.touched.content?.instagram}
                      value={formik.values.content.instagram}
                      required
                    />
                    <FieldGroupTitle>{t('common.links')}</FieldGroupTitle>
                    <FieldArray
                      name="content.links"
                      render={arrayHelpers => (
                        <DragDropContext
                          onDragEnd={({ source, destination }) => {
                            if (source.index === destination?.index) return

                            destination?.index !== undefined &&
                              arrayHelpers.move(source.index, destination.index)
                          }}
                        >
                          <Droppable droppableId="links">
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {formik.values.content.links.map(
                                  (link, index) => (
                                    <OrderableCard
                                      charKey={link.type}
                                      index={index}
                                      key={link.type}
                                      render={
                                        <Box width={1}>
                                          <div>{t(`common.${link.type}`)}</div>
                                          <SelectField
                                            disabled={isDisabled}
                                            label={t('common.link')}
                                            name={`content.links[${index}].link`}
                                            onBlur={formik.handleBlur}
                                            options={linkComponents
                                              ?.filter(
                                                link => !link.content.isInternal
                                              )
                                              .map(c => idNameObjToOption(c))}
                                            setValueFunc={formik.setFieldValue}
                                            value={
                                              formik.values.content.links[index]
                                                .link
                                            }
                                            redirection={{
                                              pathname: '/components/link',
                                            }}
                                          />
                                        </Box>
                                      }
                                    />
                                  )
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                    />
                  </Box>
                ),
              },
              {
                label: t('common.options'),
                subtitle: t('common.component_configuration'),
                disabled: true,
                render: null,
              },
            ]}
          />
        </form>
      </FormikProvider>
    </Box>
  )
}

export default SocialFeedDetailView
