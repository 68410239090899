import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import RightIcon from '@material-ui/icons/ChevronRight'
import { FC, ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Tabs from '../Tabs'
import { useStyles } from './styles'

type Tab = {
  disabled?: boolean
  extraRender?: ReactNode
  fullwidth?: boolean
  hasError?: boolean
  label: string
  render: ReactNode
  subtitle: string
  onLoad?: () => void
}

type Props = Partial<{
  hints: ReactNode[]
  tabs: Tab[]
  withHash: boolean
  testAttrNamespace?: string
  withHistory: boolean
  hideHintsByDefault: boolean
}>

const DetailedContent: FC<Props> = props => {
  const [hintsAreCollapsed, setHintsAreCollapsed] = useState(
    props.hideHintsByDefault || false
  )
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()

  const hints = useMemo(() => props.hints || [], [props.hints])
  const tabs = useMemo(() => props.tabs || [], [props.tabs])

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Tabs
        tabs={tabs}
        withHash={props.withHash}
        testAttrNamespace={props.testAttrNamespace}
        onLoad={newIndex => {
          const tab = tabs[newIndex]
          tab.onLoad && tab.onLoad()
        }}
      >
        {tabIndex =>
          tabs.length > 0 && (
            <Grid container spacing={4}>
              <Grid item xs={12} md={hintsAreCollapsed ? 1 : 3}>
                {hints.length ? (
                  <Box display="flex" minHeight="100%">
                    {((isUpMd && !hintsAreCollapsed) || !isUpMd) && (
                      <Box>
                        {hints.map((hint, index) => (
                          <Box
                            mb={index === hints.length - 1 ? 0 : 2}
                            key={index}
                          >
                            <Typography variant="body2" color="textSecondary">
                              •&#9;{hint}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                    {isUpMd && (
                      <Box>
                        <IconButton
                          className={classes.hideButton}
                          onClick={_ =>
                            setHintsAreCollapsed(!hintsAreCollapsed)
                          }
                          title={
                            hintsAreCollapsed
                              ? t('common.show_hints')
                              : t('common.hide_hints')
                          }
                        >
                          {hintsAreCollapsed ? <RightIcon /> : <LeftIcon />}
                        </IconButton>
                        <Divider
                          className={classes.divider}
                          flexItem
                          orientation="vertical"
                        />
                      </Box>
                    )}
                  </Box>
                ) : null}
              </Grid>
              <Grid
                item
                xs={12}
                md={
                  tabs[tabIndex].fullwidth
                    ? hintsAreCollapsed
                      ? 11
                      : 9
                    : hintsAreCollapsed
                    ? 8
                    : 6
                }
              >
                <Box>
                  <Box mb={3}>
                    <Typography variant="h5" component="h3">
                      {tabs[tabIndex].label}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {tabs[tabIndex].subtitle}
                    </Typography>
                  </Box>
                  <Box key={tabIndex}>
                    {tabs?.length > 0 && tabs[tabIndex].render}
                  </Box>
                </Box>
              </Grid>
              {tabs[tabIndex].extraRender && (
                <Grid item xs={12} md={3}>
                  <Box mt={9.75}>
                    {tabs?.length > 0 && tabs[tabIndex].extraRender}
                  </Box>
                </Grid>
              )}
            </Grid>
          )
        }
      </Tabs>
    </Box>
  )
}

export default DetailedContent
