import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import ChevronIcon from '@material-ui/icons/ChevronLeftRounded'
import ClearIcon from '@material-ui/icons/ClearRounded'
import HandleIcon from '@material-ui/icons/DragHandleRounded'
import { FC, ReactNode, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'

type Props = {
  charKey: string
  index: number
  render: ReactNode
  small?: boolean
  withClearButton?: boolean
  onClear?: () => void
  extendedContent?: ReactNode
}

const OrderableCard: FC<Props> = ({ small, ...props }) => {
  const [expanded, setExpanded] = useState(false)
  const { t } = useTranslation()

  const classes = useStyles({ small })

  return (
    <Draggable draggableId={props.charKey} index={props.index}>
      {provided => (
        <div
          className={classes.root}
          ref={provided?.innerRef}
          {...provided?.draggableProps}
        >
          <Paper variant="outlined" className={classes.paper}>
            <Box display="flex" alignItems="center" my={1}>
              <div className={classes.handle} {...provided?.dragHandleProps}>
                <HandleIcon />
              </div>
              {props.render}
              {props.extendedContent && (
                <div>
                  <IconButton
                    size="small"
                    title={t('common.collapse')}
                    onClick={() => setExpanded(!expanded)}
                  >
                    <ChevronIcon
                      style={{
                        transform: `rotate(${expanded ? '90' : '-90'}deg)`,
                      }}
                    />
                  </IconButton>
                </div>
              )}
              {props.withClearButton && (
                <div>
                  <IconButton
                    className={classes.clear}
                    size="small"
                    onClick={props.onClear}
                    title={t('common.delete')}
                  >
                    <ClearIcon />
                  </IconButton>
                </div>
              )}
            </Box>
            {props.extendedContent ? (
              <Box mt={1}>
                <Collapse in={expanded}>
                  <Divider />
                  <Box my={1}>{props.extendedContent}</Box>
                </Collapse>
              </Box>
            ) : null}
          </Paper>
        </div>
      )}
    </Draggable>
  )
}

export default OrderableCard
