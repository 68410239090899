import Box from '@material-ui/core/Box'
import { useFormik } from 'formik'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Prompt, useParams } from 'react-router-dom'
import { useUpdateEffect } from 'react-use'
import * as yup from 'yup'
import DetailedContent from '../../../components/DetailedContent'
import SelectField from '../../../components/SelectField'
import TextField from '../../../components/TextField'
import Title from '../../../components/Title'
import { ApiDataType } from '../../../core/api'
import { useApiCreate } from '../../../core/hooks/useApiCreate'
import { useApiData } from '../../../core/hooks/useApiData'
import { useApiMutation } from '../../../core/hooks/useApiMutation'
import { useApiUpdate } from '../../../core/hooks/useApiUpdate'
import { useHasFieldErrors } from '../../../core/hooks/useHasFieldsError'
import { idNameObjToOption } from '../../../core/tools/idNameObjToOption'
import ComponentActions from '../../ComponentActions'
import { useStyles } from './styles'

type Props = Partial<{
  create: boolean
  edit: boolean
}>

const HighContrastSwitchView: FC<Props> = ({ create, edit }) => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const apiUpdate = useApiUpdate({ apiKey: 'components', id: parseInt(id) })
  const apiCreate = useApiCreate({
    apiKey: 'components',
  })
  const { apiMutate, duplicateOnMutate } = useApiMutation({
    apiCreate,
    apiUpdate,
    pathnameOnCreate: '/components/jlc-high-contrast-switch',
  })
  const component = useApiData<ApiDataType.Component.JLC.HighContrastSwitch>({
    apiKey: 'components',
    id,
    avoid: create,
  })
  const textComponents = useApiData<ApiDataType.Component.Text[]>({
    apiKey: 'components',
    params: { slug: 'text' },
  })

  const validationSchema = yup.object({
    name: yup.string().required(t('formik.errors.required')),
    content: yup.object({
      title: yup.number().nullable().required(t('formik.errors.required')),
    }),
  })

  type FormFields = yup.TypeOf<typeof validationSchema>

  const initialValues = component || {
    name: '',
    content: {
      title: null,
    },
  }

  const formik = useFormik<FormFields>({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      const body = {
        id: component?.id,
        template: component?.template,
        slug: 'jlc-high-contrast-switch',
        ...values,
      }

      await apiMutate({ body, create, edit })

      actions.resetForm({ values })
    },
  })

  useUpdateEffect(() => {
    formik.resetForm({ values: initialValues })
  }, [component])

  const hasFieldErrors = useHasFieldErrors(formik)

  const hasOverviewError = hasFieldErrors(['name'])
  const hasContentError = hasFieldErrors(['content.title'])

  const isDisabled = useMemo(
    () => (edit && !component) || formik.isSubmitting,
    [edit, component, formik.isSubmitting]
  )

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Prompt
        when={!isDisabled && formik.dirty}
        message={_ => t('dialogs.alerts.unsaved_modifications') as string}
      />
      <form onSubmit={formik.handleSubmit}>
        <Title
          title={
            create
              ? formik.values.name ||
                t('views.high_contrast_switch.create.title')
              : formik.values.name || component?.name || '...'
          }
          subtitle={
            create
              ? t('views.high_contrast_switch.create.subtitle')
              : t('views.high_contrast_switch.edit.subtitle')
          }
          actionRender={
            <ComponentActions
              showMore={edit}
              isDirty={formik.dirty}
              isDisabled={isDisabled}
              isSubmitting={formik.isSubmitting}
              onDuplicate={() => {
                duplicateOnMutate()
                formik.submitForm()
              }}
            />
          }
          withBackButton
        />
        <DetailedContent
          hints={[]}
          tabs={[
            {
              label: t('common.overview'),
              subtitle: t('common.general_information'),
              hasError: hasOverviewError,
              render: (
                <Box>
                  <TextField
                    disabled={isDisabled}
                    error={formik.errors.name}
                    label={t('common.name')}
                    name="name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    touched={formik.touched.name}
                    value={formik.values.name}
                    required
                  />
                </Box>
              ),
            },
            {
              label: t('common.content'),
              subtitle: t('common.component_information'),
              hasError: hasContentError,
              render: (
                <Box>
                  <SelectField
                    disabled={isDisabled}
                    label={t('common.title')}
                    name="content.title"
                    error={formik.errors.content?.title}
                    onBlur={formik.handleBlur}
                    options={textComponents?.map(c => idNameObjToOption(c))}
                    setValueFunc={formik.setFieldValue}
                    touched={formik.touched.content?.title}
                    value={formik.values.content.title}
                    redirection={{
                      pathname: '/components/text',
                    }}
                    required
                  />
                </Box>
              ),
            },
            {
              label: t('common.options'),
              subtitle: t('common.component_configuration'),
              render: null,
              disabled: true,
            },
          ]}
        />
      </form>
    </Box>
  )
}

export default HighContrastSwitchView
