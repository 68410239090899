import { fade, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(10),
  },
  drawerPaper: {
    background: '#004165',
    borderRight: 'none',
    boxShadow: theme.shadows[8],
    color: theme.palette.common.white,
    width: theme.spacing(10),
  },
  divider: {
    background: fade(theme.palette.common.white, 0.05),
    height: theme.spacing(0.25),
  },
  logoNavLinkBox: {
    alignItems: 'center',
    display: 'flex',
  },
  logoNavLink: {
    fontSize: 0,
    margin: theme.spacing(1.5),
    padding: theme.spacing(1),
    width: theme.spacing(10),
  },
  navListBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  navList: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  miscBox: {
    userSelect: 'none',
  },
  circularProgress: {
    margin: theme.spacing(3.5),
  },
  trigram: {
    fontWeight: theme.typography.fontWeightMedium,
    height: theme.spacing(4),
    textAlign: 'center',
    textTransform: 'uppercase',
    width: theme.spacing(4),
    lineHeight: 2,
  },
  trigramPaper: {
    marginLeft: theme.spacing(4),
  },
}))
