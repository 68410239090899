import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    wordBreak: 'break-word',
  },
  subtitle: {
    wordBreak: 'break-word',
  },
}))
