import Box from '@material-ui/core/Box'
import { FieldArray, FormikErrors, FormikProvider, useFormik } from 'formik'
import { FC, useMemo } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Prompt, useParams } from 'react-router-dom'
import { useUpdateEffect } from 'react-use'
import * as yup from 'yup'
import { RequiredNumberSchema } from 'yup/lib/number'
import { TypeOfShape } from 'yup/lib/object'
import Button from '../../../components/Button'
import DetailedContent from '../../../components/DetailedContent'
import OrderableCard from '../../../components/OrderableCard'
import SelectField from '../../../components/SelectField'
import TextField from '../../../components/TextField'
import Title from '../../../components/Title'
import { ApiDataType } from '../../../core/api'
import { useApiCreate } from '../../../core/hooks/useApiCreate'
import { useApiData } from '../../../core/hooks/useApiData'
import { useApiMutation } from '../../../core/hooks/useApiMutation'
import { useApiUpdate } from '../../../core/hooks/useApiUpdate'
import { useHasFieldErrors } from '../../../core/hooks/useHasFieldsError'
import { idNameObjToOption } from '../../../core/tools/idNameObjToOption'
import ComponentActions from '../../ComponentActions'
import { useStyles } from './styles'

type Props = Partial<{
  create: boolean
  edit: boolean
}>

const CollectionCarouselDetailView: FC<Props> = ({ create, edit }) => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const apiUpdate = useApiUpdate({ apiKey: 'components', id: parseInt(id) })
  const apiCreate = useApiCreate({
    apiKey: 'components',
  })
  const { apiMutate, duplicateOnMutate } = useApiMutation({
    apiCreate,
    apiUpdate,
    pathnameOnCreate: '/components/jlc-collection-carousel',
  })
  const component = useApiData<ApiDataType.Component.JLC.CollectionCarousel>({
    apiKey: 'components',
    id,
    avoid: create,
  })
  const textComponents = useApiData<ApiDataType.Component.Text[]>({
    apiKey: 'components',
    params: { slug: 'text' },
  })
  const imageComponents = useApiData<ApiDataType.Component.Image[]>({
    apiKey: 'components',
    params: { slug: 'image' },
  })
  const linkComponents = useApiData<ApiDataType.Component.Link[]>({
    apiKey: 'components',
    params: { slug: 'link', withContent: 'true' },
  })

  const validationSchema = yup.object({
    name: yup.string().required(t('formik.errors.required')),
    content: yup
      .array(
        yup.object({
          orderKey: yup.string().default(Date.now().toString()),
          title: yup.number().nullable().required(t('formik.errors.required')),
          image: yup.number().nullable().required(t('formik.errors.required')),
          link: yup.number().nullable().required(t('formik.errors.required')),
        })
      )
      .default([])
      .min(1),
  })

  type FormFields = yup.TypeOf<typeof validationSchema>
  type ContentErrors = FormikErrors<
    TypeOfShape<{
      orderKey: yup.StringSchema<string, Record<string, any>, string>
      title: RequiredNumberSchema<
        number | null | undefined,
        Record<string, any>
      >
      image: RequiredNumberSchema<
        number | null | undefined,
        Record<string, any>
      >
      link: RequiredNumberSchema<number | null | undefined, Record<string, any>>
    }>
  >

  const initialValues = component || {
    name: '',
    content: [],
  }

  const formik = useFormik<FormFields>({
    initialValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      const body = {
        id: component?.id,
        template: component?.template,
        slug: 'jlc-collection-carousel',
        ...values,
      }

      await apiMutate({ body, create, edit })

      actions.resetForm({ values })
    },
  })

  useUpdateEffect(() => {
    formik.resetForm({ values: initialValues })
  }, [component])

  const hasFieldErrors = useHasFieldErrors(formik)
  const hasOverviewError = hasFieldErrors(['name'])
  const hasContentError = hasFieldErrors(['content'])

  const isDisabled = useMemo(
    () => (edit && !component) || formik.isSubmitting,
    [edit, component, formik.isSubmitting]
  )

  const testAttrNamespace = useMemo(
    () => (create ? 'collection-carousel-create' : 'collection-carousel-edit'),
    [create]
  )

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Prompt
        when={!isDisabled && formik.dirty}
        message={_ => t('dialogs.alerts.unsaved_modifications') as string}
      />
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Title
            testAttrNamespace={testAttrNamespace}
            title={
              create
                ? formik.values.name ||
                  t('views.collection_carousel.create.title')
                : formik.values.name || component?.name || '...'
            }
            subtitle={
              create
                ? t('views.collection_carousel.create.subtitle')
                : t('views.collection_carousel.edit.subtitle')
            }
            actionRender={
              <ComponentActions
                showMore={edit}
                isDirty={formik.dirty}
                isDisabled={isDisabled}
                isSubmitting={formik.isSubmitting}
                onDuplicate={() => {
                  duplicateOnMutate()
                  formik.submitForm()
                }}
              />
            }
            withBackButton
          />
          <DetailedContent
            testAttrNamespace={testAttrNamespace}
            hints={[]}
            tabs={[
              {
                label: t('common.overview'),
                subtitle: t('common.general_information'),
                hasError: hasOverviewError,
                render: (
                  <Box>
                    <TextField
                      disabled={isDisabled}
                      error={formik.errors.name}
                      label={t('common.name')}
                      name="name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      touched={formik.touched.name}
                      value={formik.values.name}
                      required
                    />
                  </Box>
                ),
              },
              {
                label: t('common.content'),
                subtitle: t('common.component_information'),
                hasError: hasContentError,
                render: (
                  <FieldArray
                    name="content"
                    render={arrayHelpers => (
                      <DragDropContext
                        onDragEnd={({ source, destination }) => {
                          if (source.index === destination?.index) return

                          destination?.index !== undefined &&
                            arrayHelpers.move(source.index, destination.index)
                        }}
                      >
                        <Box>
                          <Droppable droppableId="collections">
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {formik.values.content?.map(
                                  (collection, index) => (
                                    <OrderableCard
                                      key={collection.orderKey}
                                      charKey={collection.orderKey}
                                      withClearButton
                                      onClear={() => {
                                        arrayHelpers.remove(index)
                                      }}
                                      index={index}
                                      render={
                                        <Box width={1}>
                                          <SelectField
                                            name={`content.${index}.image`}
                                            redirection={{
                                              pathname: '/components/image',
                                            }}
                                            error={
                                              formik.errors.content &&
                                              (
                                                formik.errors
                                                  .content as ContentErrors[]
                                              )[index]?.image
                                            }
                                            touched
                                            label={t('common.image')}
                                            setValueFunc={formik.setFieldValue}
                                            onBlur={formik.handleBlur}
                                            options={imageComponents?.map(c =>
                                              idNameObjToOption(c)
                                            )}
                                            value={
                                              formik.values.content[index].image
                                            }
                                            required
                                          />
                                          <SelectField
                                            name={`content.${index}.title`}
                                            redirection={{
                                              pathname: '/components/text',
                                            }}
                                            error={
                                              formik.errors.content &&
                                              (
                                                formik.errors
                                                  .content as ContentErrors[]
                                              )[index]?.title
                                            }
                                            touched
                                            label={t('common.title')}
                                            setValueFunc={formik.setFieldValue}
                                            onBlur={formik.handleBlur}
                                            options={textComponents?.map(c =>
                                              idNameObjToOption(c)
                                            )}
                                            value={
                                              formik.values.content[index].title
                                            }
                                            required
                                          />
                                          <SelectField
                                            name={`content.${index}.link`}
                                            redirection={{
                                              pathname: '/components/link',
                                            }}
                                            error={
                                              formik.errors.content &&
                                              (
                                                formik.errors
                                                  .content as ContentErrors[]
                                              )[index]?.link
                                            }
                                            touched
                                            label={t('common.internal_link')}
                                            setValueFunc={formik.setFieldValue}
                                            onBlur={formik.handleBlur}
                                            options={linkComponents
                                              ?.filter(
                                                c => c.content.isInternal
                                              )
                                              .map(c => idNameObjToOption(c))}
                                            value={
                                              formik.values.content[index].link
                                            }
                                            required
                                          />
                                        </Box>
                                      }
                                    />
                                  )
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                          <Button
                            fullWidth
                            onClick={_ => {
                              arrayHelpers.push({
                                orderKey: Date.now().toString(),
                                title: undefined,
                                image: undefined,
                                link: undefined,
                              })
                            }}
                          >
                            {t('common.add_collection')}
                          </Button>
                        </Box>
                      </DragDropContext>
                    )}
                  />
                ),
              },
            ]}
          />
        </form>
      </FormikProvider>
    </Box>
  )
}

export default CollectionCarouselDetailView
