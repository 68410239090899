import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField'
import { FC, ReactNode, useMemo } from 'react'
import { useStyles } from './styles'

type Props = Omit<TextFieldProps, 'error'> &
  Partial<{
    error: string
    label: string
    name: string
    touched: boolean
    endIconRender: ReactNode
    onEndButtonClick: (value: unknown) => void
    endButtonTitle: string
    required: boolean
  }>

const TextField: FC<Props> = ({
  error,
  touched,
  endIconRender,
  onEndButtonClick,
  endButtonTitle,
  ...props
}) => {
  const classes = useStyles()

  const hasError = useMemo(() => touched && !!error, [touched, error])

  return (
    <Box display="flex" alignItems="flex-start" justifyContent="center">
      <MuiTextField
        {...props}
        className={classes.root}
        error={hasError}
        fullWidth
        helperText={touched && error}
        label={props.label}
        margin="normal"
        name={props.name}
        size="small"
        variant="outlined"
      />
      {endIconRender && onEndButtonClick && (
        <Box mt={2.5} ml={1} display="flex">
          <IconButton
            size="small"
            onClick={() => onEndButtonClick(props.value)}
            title={endButtonTitle}
          >
            {endIconRender}
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default TextField
